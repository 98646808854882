.timeline-card-container {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;

    width: 100vw;

    padding-top: 5em;

    scroll-snap-align: start;
}

.timeline-card {
    position: relative;

    box-sizing: border-box;
    width: 100%;

    padding: 2rem var(--h-margin) 1em var(--h-margin);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    gap: 2em;
    
    background: linear-gradient(145deg, var(--bg-b-color-1), var(--bg-b-color-2));

    filter: 
        drop-shadow(2px 2px 4px var(--shadow-color-1)) 
        drop-shadow(-2px -2px 4px var(--shadow-color-2));
}

.timeline-card::before,
.timeline-card::after {
    content: '';

    position: absolute;
    left: 0;

    width: 100%;

    background: linear-gradient(90deg, var(--bg-b-color-1), var(--bg-b-color-2));
}

.timeline-card-container:nth-child(3n) > .timeline-card::before {
    --wedge-height: 5rem;
    top: calc(-1 * var(--wedge-height) + 1px);
    height: var(--wedge-height);
    clip-path: polygon(0 100%, 100% 0, 100% 100%, 0 100%);
}

.timeline-card-container:nth-child(3n) > .timeline-card::after {
    --wedge-height: 5rem;
    bottom: calc(-1 * var(--wedge-height) + 1px);
    height: var(--wedge-height);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
}

.timeline-card-container:nth-child(3n + 1) > .timeline-card::before {
    --wedge-height: 3rem;
    top: calc(-1 * var(--wedge-height) + 1px);
    height: var(--wedge-height);
    clip-path: polygon(0 0, 100% 100%, 100% 100%, 0 100%);
}

.timeline-card-container:nth-child(3n + 1) > .timeline-card::after {
    --wedge-height: 4rem;
    bottom: calc(-1 * var(--wedge-height) + 1px);
    height: var(--wedge-height);
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
}

.timeline-card-container:nth-child(3n + 2) > .timeline-card::before {
    --wedge-height: 5rem;
    top: calc(-1 * var(--wedge-height) + 1px);
    height: var(--wedge-height);
    clip-path: polygon(0 0, 100% 100%, 100% 100%, 0 100%);
}

.timeline-card-container:nth-child(3n + 2) > .timeline-card::after {
    --wedge-height: 3rem;
    bottom: calc(-1 * var(--wedge-height) + 1px);
    height: var(--wedge-height);
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
}

.timeline-card > .date {

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    width: 15em;
}

.timeline-card > .date > h3 {
    margin: 0;
    color: var(--font-color-3);
}

.timeline-card > .content {
    flex: 1;
}

@media screen and (max-width: 1000px) {
    .timeline-card { 
        flex-direction: column;
        gap: 1em;
    }

    .timeline-card > .date {
        width: 100%;
    }
}