.card-heading > .company-name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    gap: 1em;
    text-decoration: none;

    margin: 0.5em 0 0 0;

    transform-origin: left;
    transition: .2s ease-out;
}

.card-heading h3 {
    color: var(--font-color-2);
}

.card-heading > a > svg {
    transition: .3s ease-out;
}

.card-heading > a:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.card-heading > a:hover > svg {
    transform: translateX(200%);
}