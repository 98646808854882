.info-container {
    box-sizing: border-box;

    display: grid;
    grid-template-columns: 2em 3em 8fr 8fr 4fr 6fr;
    gap: 1em;

    width: 100%;

    z-index: 1;

    min-height: 48px;
    
    padding: 8px 1em;
    
    border-radius: 24px;
    background: linear-gradient(145deg, var(--bg-t-color-1), var(--bg-t-color-2));
}

.info-container > * {
    min-height: 32px;
    box-sizing: border-box;
    overflow-x: hidden;
}

.info-container label {
    width: 2em;
    height: 2em;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    align-self: center;

    transition: .2s ease-in-out;
}

.info-container input:checked ~ label {
    transform: rotate(180deg);
}

.info-container .date {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    font-size: 1.25em;
    line-height: 1.25em;
    font-weight: 400;
    color: var(--font-color-3);

}

.info-container .title,
.info-container a:first-of-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    color: var(--font-color-1);
}

.info-container a:first-of-type {
    display: none;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;

    text-decoration: none;
}

.info-container a:first-of-type > p {
    flex: 1;
    color: var(--font-color-1);
}

.info-container a:first-of-type > div {
    --icon-width: 1.5em;
    --translate-x: 5vw;
    width: calc((var(--translate-x) + var(--icon-width) + 1vw) * 1.1);

    transform-origin: center left;
}

.info-container a:first-of-type svg {
    color: var(--font-color-1);
    transition: .3s ease-in-out;
}

.info-container a:first-of-type:hover{
    transform: scale(1.1);
}

.info-container a:first-of-type:hover svg {
    transform: translateX(var(--translate-x));
}

.info-container .language-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
}

.info-container .language-container li {
    text-decoration: none;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 1em;
    padding: 0.2em 0.5em;
    background-color: var(--l-color-a);
    color: var(--d-color-a);
}

.info-container .visibility {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.info-container .visibility > div {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
}

.info-container .visibility p {
    margin-top: 0.4em;
}

.info-container a:last-of-type {
    text-decoration: none;
    color: var(--font-color-3);

    padding-top: 4px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
}

.info-container a:last-of-type:hover {
    transform: scale(1.1);
}

.info-container a:last-of-type:hover > svg,
.info-container a:last-of-type:hover > p {
    transition: .2s ease-out;
}

.info-container a:last-of-type:hover > svg,
.info-container a:last-of-type:hover > p {
    color: var(--font-color-1);
}

.info-container a {
    transform-origin: center left;
    transition: .2s ease-in-out;

    cursor: pointer;
}

@media screen and (max-width: 1000px) {
    .info-container {
        grid-template-columns: 2em 3em 11fr 1fr 6fr;
    }

    .info-container .language-container {
        display: none;
    }

    .info-container .visibility > div {
        justify-content: center;
    }

    .info-container .visibility p {
        display: none;
    }
}

@media screen and (max-width: 700px) {
    .info-container {
        grid-template-columns: 2em 3em 1fr;
    }

    .info-container a:last-of-type,
    .info-container .title {
        display: none;
    }

    .info-container a:first-of-type {
        display: flex;
    }

    .info-container .visibility {
        display: none;
    }
}

@media screen and (max-width: 425px) {
    .info-container a:first-of-type {
        gap: 0.5em;
    }
    .info-container a:first-of-type > div {
        display: none;
    }
}

@media screen and (max-width: 350px) {
    .info-container {
        grid-template-columns: 2em 1fr;
    }


   .info-container .date {
        display: none;
    }
}