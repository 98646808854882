.loading-tile {
    flex: 1;

    width: 100%;
    min-width: 12em;
    max-width: 20em;

    padding: 1em;


    overflow: hidden;
    
    border-radius: 1.5em;
    background: linear-gradient(145deg, var(--bg-t-color-1), var(--bg-t-color-2));
    box-shadow:  2px 2px 4px var(--shadow-color-1),
                -2px -2px 4px var(--shadow-color-2);
}

.loading-tile .content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.5em;

    height: 100%;
    width: 100%;
}

.loading-tile img {
    width: 100%;
    object-fit: cover;
    border-radius: 0.5em;

    opacity: 0;
    background-color: var(--font-color-3);
}

.loading-tile .language-container {
    padding: 1em 0 0.5em 0;

    height: 1em;
    width: 100%;
}

.loading-tile .title {
    padding: 0.5em 0.4em 0 0.4em;
    color: var(--font-color-1);

    height: 1em;
    width: 100%;
}

.loading-tile .description {
    padding: 0.5em 0.6em 0 0.4em;

    height: 5em;
    width: 100%;
}

.loading-tile .loading-bar {
    position: relative;
    box-sizing: border-box;
    overflow: hidden;

    border-radius: 12px;
}

.loading-tile .loading-bar::after {
    position: absolute;
    top: 0;
    left: -200%;
    
    content: "";
    display: block;
    width: 200%;
    height: 100%;
    background: linear-gradient(90deg, transparent, var(--font-color-3), transparent);
}

.loading-tile .loading-bar:nth-child(2)::after {
    animation: loading 3s infinite;
}

.loading-tile .loading-bar:nth-child(3)::after {
    animation: loading 3s 0.5s infinite;
}

.loading-tile .loading-bar:nth-child(4)::after {
    animation: loading 3s 1s infinite;
}

@keyframes loading {
    0% {
        left: -200%;
    }
    100% {
        left: 200%;
    }
}

