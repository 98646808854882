@import url('https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700;800;900&family=Josefin+Sans:ital,wght@0,400;0,700;1,400&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;

  cursor: inherit;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;  

}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-heading);
  color: var(--font-color-1);
}

h1 {
  font-size: clamp(29px, 16vw, 67px);
  font-weight: 700;
  line-height: clamp(32px, 17vw, 89px);
}

h2 {
  font-size: clamp(26px, 15vw, 50px);
  font-weight: 600;
  line-height: clamp(29px, 16vw, 67px);
}

h3 {
  font-size: clamp(23px, 14vw, 37px);
  font-weight: 500;
  line-height: clamp(26px, 15vw, 50px);
} 

h4 {
  font-size: clamp(20px, 13vw, 28px);
  font-weight: 400;
  line-height: clamp(23px, 14vw, 37px);
}

h5 {
  font-size: clamp(18px, 12vw, 21px);
  font-weight: 300;
  line-height: clamp(20px, 13vw, 28px);
}

p {
  font-family: var(--font-body);
  font-size: var(--p1-font-size);
  line-height: var(--p1-line-height);
  color: var(--font-color-2);
}

svg {
  color: var(--font-color-2)
}

html {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: var(--font-body), sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  cursor: default;

  overflow-x: hidden;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;

  height: 100vh;

  
  --p-color-1: #0080ff;
  --p-color-2: #2b00ff;
  --p-color-3: #d400ff;
  --p-color-4: #ff0080;
  --p-color-a: rgb(128, 0, 255);

  --l-color-1: hsl(210, 100%, 70%);
  --l-color-2: hsl(250, 100%, 70%);
  --l-color-3: hsl(290, 100%, 70%);
  --l-color-4: hsl(330, 100%, 70%);
  --l-color-a: hsl(270, 100%, 70%);

  --d-color-1: hsl(210, 100%, 30%);
  --d-color-2: hsl(250, 100%, 30%);
  --d-color-3: hsl(290, 100%, 30%);
  --d-color-4: hsl(330, 100%, 30%);
  --d-color-a: hsl(270, 100%, 30%);

  --btn-color-1: hsl(270, 31%, 95%);
  --btn-color-2: hsl(270, 40%, 96%);

  --btn-color-hover-1: var(--p-color-1);
  --btn-color-hover-2: var(--p-color-2);

  --bg-t-color-1: hsl(270, 31%, 94%);
  --bg-t-color-2: hsl(270, 40%, 97%);
  --bg-t-color-3: hsl(270, 40%, 100%);

  --bg-b-color-1: hsl(270, 31%, 91%);
  --bg-b-color-2: hsl(270, 40%, 94%);
  --bg-b-color-3: hsl(270, 40%, 98%);

  --shadow-color-1: hsla(270, 9%, 85%);
  --shadow-color-2: hsla(270, 40%, 90%);

  --font-heading: 'Gabarito', sans-serif;
  --font-body: 'Josefin Sans', sans-serif;
  --font-color-1: #000;
  --font-color-2: #444;
  --font-color-3: #777;

  --p1-font-size: 16px;
  --p1-line-height: 21px;

  --p2-font-size: 12px;
  --p2-line-height: 16px;

  --heading-blend-mode: color-burn;
  --heading-opacity: .3;
  --heading-color: #3a3a3a;

  --h-margin: 15vw;
}

body.dark{
  --p-color-1: #1466b8;
  --p-color-2: #3014b8;
  --p-color-3: #9c14b8;
  --p-color-4: #b81466;
  --p-color-a: hsl(270, 80%, 40%);

  --l-color-1: hsl(210, 80%, 70%);
  --l-color-2: hsl(250, 80%, 70%);
  --l-color-3: hsl(290, 80%, 70%);
  --l-color-4: hsl(330, 80%, 70%);
  --l-color-a: hsl(270, 80%, 70%);

  --d-color-1: hsl(210, 80%, 30%);
  --d-color-2: hsl(250, 80%, 30%);
  --d-color-3: hsl(290, 80%, 30%);
  --d-color-4: hsl(330, 80%, 30%);
  --d-color-a: hsl(270, 80%, 30%);

  --btn-color-1: hsl(270, 20%, 24%);
  --btn-color-2: hsl(270, 12%, 20%);

  --btn-color-hover-1: var(--p-color-3);
  --btn-color-hover-2: var(--p-color-4);

  --bg-t-color-1: hsl(270, 20%, 18%);
  --bg-t-color-2: hsl(270, 12%, 15%);
  --bg-t-color-3: hsl(270, 20%, 12%);

  --bg-b-color-1: hsl(270, 20%, 15%);
  --bg-b-color-2: hsl(270, 12%, 12%);
  --bg-b-color-3: hsl(270, 20%, 9%);

  --shadow-color-1: hsla(0, 0, 0, 0);
  --shadow-color-2: hsla(0, 0, 0, 0);

  --font-color-1: hsl(0, 0%, 100%);
  --font-color-2: hsl(0, 0%, 73%);
  --font-color-3: hsl(0, 0%, 53%);

  --heading-blend-mode: overlay;
  --heading-opacity: .3;
  --heading-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}