.card-footer > .project-links {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 1em 2em;

    margin: 1.5em 0.8em;

    list-style: none;
}

.card-footer > .project-links a {
    text-decoration: none;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5em;

    cursor: pointer;

    transition: .2s ease-out;
}

.card-footer > .project-links a:hover {
    transform: scale(1.2);
}

.card-footer > .project-links a > p {
    color: var(--font-color-1);
}

.card-footer > .language-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;

    margin: 1em 0 0 0;

    list-style: none;
}

.card-footer > .language-list li {
    text-decoration: none;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 1em;
    padding: 0.5em 1em;
    background-color: var(--l-color-a);
    color: var(--d-color-a);

    font-size: var(--p2-font-size);

}