.bio {
    position: relative;

    z-index: 1;

    width: 100%;

    padding: 5em 0;

    display: flex;
    justify-content: center;
    align-items: center;

    
    background: linear-gradient(145deg, var(--bg-t-color-1), var(--bg-t-color-2));

    filter: 
        drop-shadow(2px 2px 4px var(--shadow-color-1)) 
        drop-shadow(-2px -2px 4px var(--shadow-color-2));
}

.bio::before,
.bio::after {
    content: "";

    position: absolute;
    left: 0;

    background: linear-gradient(90deg, var(--bg-t-color-1), var(--bg-t-color-2));
    width: 100vw;
    height: var(--wedge-height);
}

.bio::before {
    --wedge-height: 5em;
    top: calc(-1 * var(--wedge-height));

    clip-path: polygon(0 100%, 100% 0, 100% 100%, 0 100%);
}

.bio::after {
    --wedge-height: 3em;
    bottom: calc(-1 * var(--wedge-height));
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
}

.bio .bio-bg {
    position: absolute;
    top: 0;
    left: 20vw;

    width: 100vw;
    height: 100%;
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.bio .bio-info {
    margin: 2em var(--h-margin);

    display: flex;
    justify-content: center;
    align-items: center;
}

.bio .bio-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    gap: 1em;

    text-align: center;
}

.bio .bio-text p {
    color: var(--font-color-1);
}