.expansion-container {
    position: relative;
    top: -1.5em;

    box-sizing: border-box;

    width: 100%;
    height: 100%;
    max-height: 0;

    transition: .2s ease-in-out;
    
}

.projects-tile > input:checked ~ .expansion-container {
    max-height: 15em;
}

.expansion {
    box-sizing: border-box;

    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: 1fr 5em;
    grid-template-rows: fit-content(12em);
    gap: 1em;

    overflow: hidden;

    padding: 2.5em 4em 1em 4em;

    border-radius: 0 0 1.5em 1.5em;
    background: linear-gradient(145deg, var(--bg-b-color-1), var(--bg-b-color-2));
}

.expansion-container .description-container {
    --blur-height: 1em;
    --scroll-track-width: 0.5em;

    position: relative;
    overflow: hidden;
    height: 100%;
}

.expansion-container .description {
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1em;

    height: 100%;
    width: 100%;

    padding: var(--blur-height) 0;

    overflow-y: scroll;

    color: var(--font-color-2);
}

.expansion-container .description::-webkit-scrollbar {
    width: var(--scroll-track-width);
}

.expansion-container .description::-webkit-scrollbar-track {
    background-color: transparent;
}

.expansion-container .description::-webkit-scrollbar-thumb {
    background-color: var(--font-color-3);
    border-radius: 0.25em;
}

.expansion-container .description::-webkit-scrollbar-thumb:hover {
    background-color: var(--font-color-2);
}

.expansion-container .description::-webkit-scrollbar-button {
    display: none;
}

.expansion-container .language-container {
    display: none;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    gap: 0.5em;
}

.expansion-container .language-container li {
    text-decoration: none;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 1em;
    padding: 0.2em 0.5em;
    background-color: var(--l-color-a);
    color: var(--d-color-a);

    font-size: var(--p2-font-size);
    line-height: var(--p2-line-height);
}

.expansion-container .stats-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    color: var(--font-color-3);
}

.expansion-container .stats-container > div {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
    
    padding: 0.3em 0;
}

.expansion-container .stats-container > div svg:first-child {
    width: 2em;
}

.expansion-container .stats-container .visibility-container {
    display: none;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.expansion-container .stats-container .visibility-container > div {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
}

.expansion-container .stats-container .visibility-container p {
    margin-top: 0.4em;
}

@media screen and (max-width: 1000px) {
    .expansion-container .expansion {
        grid-template-columns: 1fr 12em 5em;
    }

    .expansion-container .language-container {
        display: flex;
    }
}

@media screen and (max-width: 800px) {
    .expansion-container .expansion {
        grid-template-columns: 1fr;
        grid-template-rows: max-content max-content fit-content(50vh);
        gap: 0.5em;
        height: 100%;
        padding: 2.5em 2em 1em 2em;
    }
    
    .expansion-container .language-container {
        order: 2;
    }

    .expansion-container .stats-container {
        order: 1;
        width: 100%;
        flex-flow: row wrap;
        justify-content: flex-start;
        gap: 0 2em;

        margin-right: 0.5em;

        font-size: 0.8em;
    }

    .expansion-container .stats-container > div {
        width: unset;
        gap: 0.5em;
    }

    .expansion-container .description-container {
        order: 3;
        width: 100%;
    }
    
    .projects-tile > input:checked ~ .expansion-container {
        max-height: 70vh
    }
}

@media screen and (max-width: 700px) {
    .expansion-container .stats-container .visibility-container {
        display: flex;
    }
}