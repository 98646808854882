nav {
    position: fixed;

    z-index: 10;

    width: 100vw;

    display: flex;
    align-items: center;
    justify-content: center;
}

nav ul {
    padding: 0.5em;
    margin: 0.5em;

    display: grid;
    grid-template-columns: repeat(3, 4fr) 3fr;
    gap: 0.5em;

    border-radius: 2em;
    background: linear-gradient(145deg, var(--bg-b-color-1), var(--bg-b-color-2));

    list-style: none;
}

nav li {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

nav li:last-of-type {
    gap: 0.5em;
}

nav .link {
    height: 100%;
    padding: 0.2em 1em;

    
    color: var(--font-color-1);
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    text-decoration: none;
}

nav li p {
    text-wrap: nowrap;
}

@media screen and (max-width: 1200px) {
    nav li .nav-icon {
        width: 0;
        visibility: hidden;
        display: none;
    }
}

@media screen and (max-width: 786px) {
    nav ul {
        max-width: 20em;
        width: clamp(10em, 100%, 20em);
        justify-content: space-between;

        margin: 0.5em 1em;;
    }

    nav .button {
        padding: 0em;
    }

    nav li svg {
        width: 1.5em;
        visibility: visible;
        display: block;
    }

    nav li p {
        display: none;
    }

    nav li:last-of-type svg {
        display: none;
    }
}

