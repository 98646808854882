.highlights {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: max-content;

    padding: 0 var(--h-margin);
}

.highlights::after {
    content: "";

    --wedge-size: 5em;

    position: absolute;
    bottom: calc(-1 * var(--wedge-size));
    left: 0;

    width: 100%;
    height: var(--wedge-size);

    background: linear-gradient(145deg, var(--bg-t-color-1), var(--bg-t-color-2));

    clip-path: polygon(0 100%, 100% 0, 100% 100%, 0 100%);

}

.highlights .heading-bg {
    height: 8em;
    clip-path: polygon(0 0, 100% 0, 100% 3em, 0% 100%);
}

.highlights .heading-content {
    padding-top: 4em;
}

.highlights h2 {
    font-size: clamp(2em, 10vw, 4em);
    font-weight: 600;
    letter-spacing: 0.1em;
    font-family: var(--font-heading), sans-serif;
}

.highlights .tile-container {
    width: 100%;

    margin: 5rem 0;
    padding-bottom: 5em;

    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: stretch;

    gap: 2rem;
}

.highlights .tile:only-child {
    margin: 0 auto;
}

@media screen and (max-width: 776px) {
    .highlights {
        justify-content: center;
    }
}