.projects-error-tile {
    box-sizing: border-box;

    display: grid;
    grid-template-columns: 2em 3fr 9fr;
    gap: 1em;

    width: 100%;

    min-height: 48px;
    
    padding: 8px 1em;
    
    border-radius: 24px;
    background: linear-gradient(145deg, var(--bg-t-color-1), var(--bg-t-color-2));
}

.projects-error-tile > * {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

