.projects {
    margin: 10em 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
}

.projects > .table-header {
    box-sizing: border-box;

    display: grid;
    grid-template-columns: 3em 8fr 8fr 4fr 6fr;
    gap: 1em;

    width: 100%;

    z-index: 1;

    min-height: 2em;
    
    padding: 0.2em 2em 0.2em 4em;
    
    border-radius: 1.5em;
    background: linear-gradient(145deg, var(--bg-t-color-1), var(--bg-t-color-2));
    filter: 
        drop-shadow(5px 5px 10px var(--shadow-color-1)) 
        drop-shadow(-5px -5px 10px var(--shadow-color-2));
}

.projects > .table-header > * {
    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1em;
    font-weight: 600;
    color: var(--font-color-1)
}

@media screen and (max-width: 1000px) {
    .projects > .table-header {
        grid-template-columns: 3em 11fr 1fr 6fr;
    }

    .projects > .table-header .languages {
        display: none;
    }
}

@media screen and (max-width: 700px) {
    .projects > .table-header {
        grid-template-columns: 3em 1fr;
    }

    .projects > .table-header .link {
        display: none;
    }

    .projects > .table-header .visibility {
        display: none;
    }
}