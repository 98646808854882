.hero-bio {
    position: relative;
    max-width: 30em;
    margin: 2em 0 1em 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1em;

    font-family: var(--font-body), sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
}

.hero-bio .hero-bio-text:first-child {
    color: var(--font-color-1)
}

.hero-bio .hero-bio-text:last-child {
    color: var(--font-color-2);
}