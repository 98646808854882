.heading-container {
    position: relative;

    box-sizing: border-box;

    width: 100vw;
}

.heading-bg {
    position: absolute;

    top: var(--v-margin);
    left: calc(-1 * var(--h-margin));

    width: 100vw;

    background: linear-gradient(145deg, var(--bg-b-color-1), var(--bg-b-color-2));

    filter: 
        drop-shadow(2px 2px 4px var(--shadow-color-1)) 
        drop-shadow(-2px -2px 4px var(--shadow-color-2));
}

.heading-visible {
    position: absolute;
}

.heading-container .heading-split {
    top: calc(-1 * var(--v-margin));
    left: var(--h-margin);

    opacity: 1;
    color: var(--font-color-1);
}

.heading-container > .heading-burn {
    mix-blend-mode: var(--heading-blend-mode);
    color: var(--heading-color);
    opacity: 1;

    z-index: 2;
}

.heading-container > .heading-overlay {
    opacity: var(--heading-opacity);
    color: var(--heading-color);

    z-index: 2;
}

.heading-invisible {
    visibility: hidden;
}