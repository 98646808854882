.about-description {
    position: relative;

    left: calc(-1 * var(--h-margin));

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;

    width: 100vw;

    padding: 7vh var(--h-margin) 3vh var(--h-margin);

    background: linear-gradient(145deg, var(--bg-t-color-1), var(--bg-t-color-2));
    filter: 
        drop-shadow(2px 2px 4px var(--shadow-color-1)) 
        drop-shadow(-2px -2px 4px var(--shadow-color-2));

}

.about-description::before {
    --wedge-height: 3em;

    content: "";
    position: absolute;
    top: calc(-1 * var(--wedge-height) + 1px);
    left: 0;

    width: 100vw;
    height: var(--wedge-height);

    background: linear-gradient(90deg, var(--bg-t-color-1), var(--bg-t-color-2));
    clip-path: polygon(0 0, 100% 100%, 100% 100%, 0% 100%);
}

.about-description::after {
    --wedge-height: 6em;

    content: "";
    position: absolute;
    bottom: calc(-1 * var(--wedge-height) + 1px);
    left: 0;

    width: 100vw;
    height: var(--wedge-height);

    background: linear-gradient(90deg, var(--bg-t-color-1), var(--bg-t-color-2));
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 0%);
}

.about-description p {
    position: relative;

    margin: 0 0 5vh 0;
    padding: 0 5vw;

    width: 100%;

    text-align: center;
    color: var(--font-color-2)
}