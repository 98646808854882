.hero-socials {
    margin: 1vw 0;
    max-width: 30em;
    width: 100%;

    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 1em;

    --btn-font-size: 1.2rem;
    --btn-icon-size: 1.5rem;
}

.hero-socials .button-container {
    width: 12em;
}

@media screen and (max-width: 400px) {
    .hero-socials {
        flex-direction: column;
        padding: 0;
        margin: 0;
    }

    .hero-socials .button-container {
        margin: 0.5em 0;
    }
}