.button {
    position: relative;
    overflow: hidden;

    box-sizing: border-box;

    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--font-color-1);

    border-radius: 1.5em;
    background: linear-gradient(145deg, var(--btn-color-1), var(--btn-color-2));
    box-shadow:  2px 2px 4px var(--shadow-color-1),
                -2px -2px 4px var(--shadow-color-2);

    
    padding: 0em 2em;

    height: 2.5em;
    width: 100%;

    border: none;

    cursor: pointer;
}

.button::before {
    content: '';
    width: 100%;
    height: 100%;
    
    position: absolute;
    top: 100%;
    left: 0;
    background-image: linear-gradient(
        to top right, var(--btn-color-hover-1) 0%, var(--btn-color-hover-2) 100%
    );
    transition: .2s ease-out;
    display: block;
    z-index: -1;
}

.button:hover::before {
    transform: translateY(-100%);
}

.button > * {

    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1em;

    text-decoration: none;
}

.button svg {
    font-size: 1.5em;
}

.button p {
    padding-top: 0.3em;
    margin: 0;
}

.button svg,
.button p {
    color: var(--font-color-1);
    transition: .1s ease-out;
}

.button:hover svg,
.button:hover p {
    color: white;
}