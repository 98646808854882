.error-tile {
    flex: 1;

    width: 100%;
    min-width: 12em;
    max-width: 20em;

    padding: 1em;


    overflow: hidden;
    
    border-radius: 1.5em;
    background: linear-gradient(145deg, var(--bg-t-color-1), var(--bg-t-color-2));
    box-shadow:  2px 2px 4px var(--shadow-color-1),
                -2px -2px 4px var(--shadow-color-2);
}

.error-tile .content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.5em;

    height: 100%;
    width: 100%;
}

.error-tile .img-container {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    background-color: var(--font-color-3);

    opacity: .5;

    border-radius: 0.5em;
}

.error-tile img {
    width: 100%;
    object-fit: cover;

    opacity: 0;
}

.error-tile .language-container {
    padding: 1em 0 0.5em 0;

    height: 1em;
    width: 100%;
}

.error-tile .title {
    padding: 0.5em 0.4em 0 0.4em;
    color: var(--font-color-1);

    height: 1em;
    width: 100%;
}

.error-tile .description {
    padding: 0.5em 0.6em 0 0.4em;

    height: 5em;
    width: 100%;
}

