.projects-page {
    min-height: 100vh;

    padding: 0 var(--h-margin)
}

.projects-page .heading-bg {
    --v-margin: 8em;

    height: 17em;

    clip-path: polygon(0 0, 100% 100%, 100% 100%, 0% 100%);
}

.projects-page .heading-content {
    padding-top: 10em;
    padding-bottom: 5em;
}

.projects-page h1 {
    font-size: clamp(1em, 13vw, 8em);
    font-weight: 700;
    letter-spacing: 0.1em;
    font-family: var(--font-heading), sans-serif;
}

@media screen and (max-width: 900px) {
    .projects-page .heading-content {
        text-align: center;
        left: 0;
    }

    .projects-page .heading-content > h1 {
        margin: 0 0 5vh 0;
    }
}

@media screen and (max-width: 700px) {
    .projects-page {
        --h-margin: 10vw;
    }
}